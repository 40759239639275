

















































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import moment from "moment";
import Common from "../../common/Common";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Paginate from "vuejs-paginate";
require("moment-duration-format");

@Component({
  components: {
    ClipLoader,
    Paginate
  }
})
export default class ChatList extends Vue {
  LOAD_COUNT = 30;
  GET_MAX_PAGE = 5;
  isLoading = true;
  searchValue = "";
  rooms: {
    id: string;
    onayamiId: string;
    name: string;
    title: string;
    patient: string;
    clinic: string;
    status: string;
    chat: string;
    chatNum: number;
    chatUid: string;
    date: Date;
    uids: string[];
    isClinic: boolean;
    imageUrl: string;
  }[] = [];
  viewRooms: {
    id: string;
    onayamiId: string;
    name: string;
    title: string;
    patient: string;
    clinic: string;
    status: string;
    chat: string;
    chatNum: number;
    chatUid: string;
    date: Date;
    uids: string[];
    isClinic: boolean;
    imageUrl: string;
  }[] = [];
  viewTab = false;
  status = "";
  position = "";
  alreadyReadResult: any = "";
  unsubscribe: any;
  page = 1;
  pages = 1;
  userInfo: any = {};
  isClosed = false;
  ts: number = 0;

  clickCallback(pageNum: number) {
    this.isLoading = true;
    this.page = pageNum;
    this.$router.replace({
      name: "chatlist",
      query: { page: String(pageNum) }
    });

    this.viewRooms = [];
    let promises: any[] = [];
    let uid = Common.getUserId();

    // 二つ先のpageのデータがない場合は取得
    if (
      this.alreadyReadResult &&
      (pageNum + 2) * this.LOAD_COUNT > this.rooms.length
    ) {
      promises.push(
        new Promise(resolve => {
          this.query(this.alreadyReadResult).then((data: any) => {
            this.setRoom(data.docs, this.pages);
            resolve();
          });
        })
      );
    }
    // 二つ前のpageのデータがない場合は取得
    else if (
      pageNum > this.GET_MAX_PAGE - 2 &&
      (!this.rooms[pageNum - 2 * this.LOAD_COUNT - 1] ||
        !this.rooms[pageNum - 2 * this.LOAD_COUNT - 1].id)
    ) {
      let date: any;
      let index = 0;
      for (let i = 0; !date; i++) {
        date = this.rooms[i] ? this.rooms[i].date : null;
        index = i;
      }

      index =
        index > this.GET_MAX_PAGE + 2
          ? index - this.LOAD_COUNT * this.GET_MAX_PAGE
          : 0;
      promises.push(
        new Promise(resolve => {
          this.query(date, 0, true).then((data: any) => {
            this.setRoom(data.docs.reverse(), 0, index);
            resolve();
          });
        })
      );
    } else {
      // 指定されたpageのデータのみをviewRoomsに設定
      let index = 0;
      for (
        let i = (this.page - 1) * this.LOAD_COUNT;
        this.viewRooms.length < this.LOAD_COUNT;
        i++
      ) {
        let room = this.rooms[i];
        if (!room) {
          break;
        }
        this.viewRooms.push(room);
        this.getRoomInfo(promises, room.chatUid, room.onayamiId, index);
        index++;
      }
    }

    Promise.all(promises).finally(() => {
      let count = this.getLoadFirstCount();
      if (count == 0) {
        sessionStorage.setItem(
          "chatSearch",
          JSON.stringify({ status: this.status, position: this.position })
        );
      } else {
        sessionStorage.setItem(
          "chatSearch",
          JSON.stringify({
            status: this.status,
            position: this.position,
            alreadyLoadCount: count,
            start: this.rooms[count].date
          })
        );
      }
      this.isLoading = false;
    });
  }

  getLoadFirstCount() {
    if (this.page < this.GET_MAX_PAGE + 3) {
      return 0;
    }
    let surplus = this.page % this.GET_MAX_PAGE;
    let alreadyLoadPage;
    if (surplus == 1) {
      alreadyLoadPage = this.page - this.GET_MAX_PAGE - surplus;
    } else if (surplus == 2 || surplus == 3 || surplus == 4) {
      alreadyLoadPage = this.page - surplus;
    } else {
      alreadyLoadPage = this.page - this.GET_MAX_PAGE;
    }
    return alreadyLoadPage * this.LOAD_COUNT;
  }

  mounted() {
    this.ts = new Date().getTime();

    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    } else {
      sessionStorage.removeItem("chatSearch");
    }
    this.isClosed = !!this.$route.query.closed;
    this.search(false, this.page);

    Common.getUserInfo().then((userInfo: any) => {
      this.userInfo = userInfo;
    });

    let uid = Common.getUserId();
    Common.getUserInfo().then((userInfo: any) => {
      const roomRef = userInfo.gid
        ? firebase
            .firestore()
            .collectionGroup("rooms")
            .where("gids", "array-contains", userInfo.gid)
        : firebase
            .firestore()
            .collectionGroup("rooms")
            .where("patient", "==", uid);
      this.unsubscribe = roomRef.onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          let data = change.doc.data()!;
          if (change.type === "modified") {
            let id = change.doc.id;
            let existData = false;
            for (let i = 0; i < this.rooms.length; i++) {
              if (this.rooms[i].id == id) {
                let forOnlyGroup = this.rooms[i].isClinic && !!data.groupText;
                this.rooms[i].chat = forOnlyGroup
                  ? data.groupText
                  : data.chatText;
                this.rooms[i].status = data.status;
                this.rooms[i].chatNum = data.chatUid != uid ? data.chatNum : 0;
                this.rooms[i].chatNum += this.rooms[i].isClinic
                  ? data.clinicChatNum
                  : data.studentChatNum;
                this.rooms[i].chatUid = forOnlyGroup ? "admin" : data.chatUid;
                this.rooms[i].date = forOnlyGroup
                  ? data.groupChatCreateTime.toDate()
                  : data.chatCreateTime
                  ? data.chatCreateTime.toDate()
                  : "";
                existData = true;
                break;
              }
            }
            this.rooms.sort(
              (a, b) =>
                firebase.firestore.Timestamp.fromDate(b.date).seconds -
                firebase.firestore.Timestamp.fromDate(a.date).seconds
            );
            this.viewRooms = [];
            let index = 0;
            let promises: Promise<any>[] = [];
            for (
              let i = (this.page - 1) * this.LOAD_COUNT;
              this.viewRooms.length < this.LOAD_COUNT;
              i++
            ) {
              let room = this.rooms[i];
              if (!room) {
                break;
              }
              this.viewRooms.push(room);
              this.getRoomInfo(promises, room.chatUid, room.onayamiId, index);
              index++;
            }
            Promise.all(promises);
            if (!existData) {
              if (this.page == 1 && data.chatStart) {
                let forOnlyGroup = data.patient != uid && !!data.groupText;
                let obj = <any>{
                  id: change.doc.id,
                  onayamiId: change.doc.ref.parent.parent!.id,
                  patient: data.patient,
                  clinic: data.clinic,
                  status: data.status,
                  startTime: data.startTime,
                  isClinic: data.patient != uid,
                  chatNum: data.chatUid != uid ? data.chatNum : 0,
                  chat: forOnlyGroup ? data.groupText : data.chatText,
                  chatUid: forOnlyGroup ? "admin" : data.chatUid,
                  date: forOnlyGroup
                    ? data.groupChatCreateTime.toDate()
                    : data.chatCreateTime
                    ? data.chatCreateTime.toDate()
                    : "",
                  uids: data.uids,
                  imageUrl: ""
                };
                obj.chatNum += obj.isClinic
                  ? data.clinicChatNum
                  : data.studentChatNum;
                this.rooms.unshift(obj);
                this.viewRooms.unshift(obj);
                let promises: any[] = [];
                this.getRoomInfo(promises, data.chatUid, obj.onayamiId, 0);
                Promise.all(promises);
              }
            }
          } else if (
            change.type === "added" &&
            data.chatCreateTime.seconds * 1000 > this.ts
          ) {
            this.ts = new Date().getTime();
            let forOnlyGroup = data.patient != uid && !!data.groupText;
            let obj = <any>{
              id: change.doc.id,
              onayamiId: change.doc.ref.parent.parent!.id,
              patient: data.patient,
              clinic: data.clinic,
              status: data.status,
              startTime: data.startTime,
              isClinic: data.patient != uid,
              chatNum: data.chatUid != uid ? data.chatNum : 0,
              chat: forOnlyGroup ? data.groupText : data.chatText,
              chatUid: forOnlyGroup ? "admin" : data.chatUid,
              date: forOnlyGroup
                ? data.groupChatCreateTime.toDate()
                : data.chatCreateTime
                ? data.chatCreateTime.toDate()
                : "",
              uids: data.uids,
              imageUrl: ""
            };
            obj.chatNum += obj.isClinic
              ? data.clinicChatNum
              : data.studentChatNum;
            this.rooms.unshift(obj);
            this.viewRooms.unshift(obj);
            this.rooms.sort(
              (a, b) =>
                firebase.firestore.Timestamp.fromDate(b.date).seconds -
                firebase.firestore.Timestamp.fromDate(a.date).seconds
            );
            this.viewRooms.sort(
              (a, b) =>
                firebase.firestore.Timestamp.fromDate(b.date).seconds -
                firebase.firestore.Timestamp.fromDate(a.date).seconds
            );
            let promises: any[] = [];
            this.getRoomInfo(promises, data.chatUid, obj.onayamiId, 0);
            Promise.all(promises);
          }
        });
      });
    });
  }

  search(conditionChange: boolean, page?: number) {
    this.isLoading = true;
    this.rooms = [];
    this.viewRooms = [];

    let session: any = sessionStorage.getItem("chatSearch");
    if (session) {
      session = JSON.parse(session);
    }
    let start: any;
    let alreadyLoadCount: any;
    let alreadyLoadPage: any = 0;
    if (!conditionChange) {
      this.status = session && session.status ? session.status : this.status;
      this.position =
        session && session.position ? session.position : this.position;

      start = session && session.start ? new Date(session.start) : null;
      alreadyLoadCount =
        session && session.alreadyLoadCount ? session.alreadyLoadCount : null;
      alreadyLoadPage = Math.floor(alreadyLoadCount / this.LOAD_COUNT);
      if (alreadyLoadPage + this.GET_MAX_PAGE * 2 < this.page) {
        this.page = 1;
        this.$router.replace({ name: "chatlist", query: { page: String(1) } });
        alreadyLoadCount = 0;
        alreadyLoadPage = 0;
      }
    }

    this.query(start, alreadyLoadCount).then((data: any) => {
      this.setRoom(data.docs, alreadyLoadPage);
    });
    if (conditionChange) {
      this.page = 1;
      this.$router.replace({ name: "chatlist", query: { page: String(1) } });
      sessionStorage.setItem(
        "chatSearch",
        JSON.stringify({ status: this.status, position: this.position })
      );
    }
  }

  query(start?: any, noLoadCount?: number, beforeLoad = false) {
    let uid = Common.getUserId();
    return new Promise((resolve, reject) => {
      Common.getUserInfo()
        .then((userInfo: any) => {
          const roomRef = userInfo.gid
            ? firebase
                .firestore()
                .collectionGroup("rooms")
                .where("gids", "array-contains", userInfo.gid)
            : firebase
                .firestore()
                .collectionGroup("rooms")
                .where("patient", "==", uid);
          let collection = roomRef.where("chatStart", "==", true);
          if (this.status) {
            collection = collection.where("status", "==", this.status);
          }
          if (this.position) {
            if (this.position == "1") {
              collection = collection.where("patient", "!=", uid);
            } else {
              collection = collection.where("patient", "==", uid);
            }
          }
          if (this.isClosed) {
            collection = collection.where("status", "==", "finish");
          } else {
            collection = collection.where("status", "in", [
              "before",
              "calling",
              "take",
              "fail",
              "cancel"
            ]);
          }

          if (beforeLoad) {
            collection = collection
              .orderBy("chatCreateTime")
              .startAfter(start)
              .limit(this.LOAD_COUNT * this.GET_MAX_PAGE);
          } else if (start) {
            if (noLoadCount) {
              for (let i = 0; i < noLoadCount; i++) {
                if (noLoadCount == i + 1) {
                  this.rooms.push(<any>{ date: start });
                } else {
                  this.rooms.push(<any>null);
                }
              }
              collection = collection
                .orderBy("chatCreateTime", "desc")
                .startAt(start)
                .limit(this.LOAD_COUNT * this.GET_MAX_PAGE * 2);
            } else {
              collection = collection
                .orderBy("chatCreateTime", "desc")
                .startAfter(start)
                .limit(this.LOAD_COUNT * this.GET_MAX_PAGE);
            }
          } else {
            collection = collection
              .orderBy("chatCreateTime", "desc")
              .limit(this.LOAD_COUNT * this.GET_MAX_PAGE * 2);
          }
          resolve(collection.get());
        })
        .catch(e => {
          reject();
        });
    });
  }

  setRoom(
    data: firebase.firestore.QueryDocumentSnapshot[],
    pages?: number,
    unshiftIndex: any = null
  ) {
    let promises: any = [];
    let uid = Common.getUserId();
    data.forEach((room, index) => {
      let result = room.data();
      let isClinic = result.patient != uid;
      let forOnlyGroup = isClinic && !!result.groupText;
      let obj = <any>{
        id: room.id,
        onayamiId: room.ref.parent.parent!.id,
        patient: result.patient,
        clinic: result.clinic,
        status: result.status,
        startTime: result.startTime,
        isClinic: isClinic,
        chatNum: result.chatUid != uid ? result.chatNum : 0,
        chat: forOnlyGroup ? result.groupText : result.chatText,
        chatUid: forOnlyGroup ? "admin" : result.chatUid,
        date: forOnlyGroup
          ? result.groupChatCreateTime.toDate()
          : result.chatCreateTime
          ? result.chatCreateTime.toDate()
          : "",
        uids: result.uids,
        imageUrl: ""
      };
      obj.chatNum += obj.isClinic
        ? result.clinicChatNum
        : result.studentChatNum;
      if (unshiftIndex || unshiftIndex == 0) {
        this.rooms[unshiftIndex++] = obj;
      } else {
        this.rooms.push(obj);
      }
      this.rooms.sort(
        (a, b) =>
          firebase.firestore.Timestamp.fromDate(b.date).seconds -
          firebase.firestore.Timestamp.fromDate(a.date).seconds
      );
    });
    let index = 0;
    for (
      let i = (this.page - 1) * this.LOAD_COUNT;
      this.viewRooms.length < this.LOAD_COUNT;
      i++
    ) {
      let room = this.rooms[i];
      if (!room) {
        break;
      }
      this.viewRooms.push(room);
      this.getRoomInfo(promises, room.chatUid, room.onayamiId, index);
      index++;
    }

    if (!unshiftIndex && unshiftIndex != 0) {
      this.pages = pages
        ? pages + Math.floor(data.length / this.LOAD_COUNT)
        : data.length / this.LOAD_COUNT;
      this.alreadyReadResult =
        data.length == this.LOAD_COUNT * this.GET_MAX_PAGE ||
        data.length == this.LOAD_COUNT * this.GET_MAX_PAGE * 2
          ? this.rooms[this.rooms.length - 1].date
          : null;
    }

    return Promise.all(promises).finally(() => {
      this.isLoading = false;
    });
  }

  getRoomInfo(
    promises: any[],
    chatUid: string,
    onayamiId: string,
    index: number
  ) {
    if (chatUid == "admin") {
      let room = this.viewRooms[index];
      room.name = "ボットKei子ちゃん";
      room.imageUrl = require("../../assets/images/4step-image1.png");
      this.$set(this.viewRooms, index, room);
    } else {
      promises.push(
        new Promise(resolve => {
          firebase
            .firestore()
            .collection("users")
            .doc(chatUid)
            .get()
            .then(user => {
              let userData = user.data()!;
              let room = this.viewRooms[index];
              room.name = userData.name;
              room.imageUrl = Common.createUserIconURL(
                chatUid,
                userData.fileExist
              );
              this.$set(this.viewRooms, index, room);
            })
            .finally(() => {
              resolve();
            });
        })
      );
    }
    promises.push(
      new Promise(resolve => {
        firebase
          .firestore()
          .collection("onayamis")
          .doc(onayamiId)
          .get()
          .then(onayami => {
            let room = this.viewRooms[index];
            room.title = onayami.data()!.title;
            this.$set(this.viewRooms, index, room);
          })
          .finally(() => {
            resolve();
          });
      })
    );
  }

  toggleClosed() {
    location.href = `/chatlist${this.isClosed ? "" : "?closed=true"}`;
  }

  select(room: firebase.firestore.DocumentData) {
    this.$router.push(`onayamis/${room.onayamiId}/chat/${room.id}`);
  }

  beforeDestroy() {
    this.unsubscribe ? this.unsubscribe() : null;
  }
}
